<template>
    <div id="products-list">
        <b-overlay :show="showLoading" spinner-variant="primary">



            <b-overlay :show="busy" no-wrap>
                <template #overlay>
                    <div v-if="processing" class="text-center p-4 bg-primary text-light rounded">
                        <feather-icon icon="UploadCloudIcon" size="50" />
                        <div>Processing...</div>
                        <div class="mb-3">{{ counter }}%</div>
                        <b-progress min="0" max="100" :value="counter" variant="success" height="3px"
                            class="mx-n4 rounded-0"></b-progress>
                    </div>
                </template>
            </b-overlay>



            <b-card>

                <b-row>
                    <b-col cols="12" md="5">
                        <b-tabs pills nav-class="nav-pill-PRIMARY">
                            <b-tab title="MEDICAMENTS" active @click="medClicked" />
                            <b-tab title="PARA-MEDICAMENTS" @click="paraClicked" />
                        </b-tabs>
                    </b-col>
                    <b-col cols="12" md="6">
                        <b-form-input @input="updateSearchQuery" v-model="searchQuery" placeholder="Recherche"
                            type="text" class="d-inline-block" />
                    </b-col>
                    <b-col cols="12" md="1">
                        <b-button variant="outline-primary" class="btn-icon" block v-b-tooltip.hover
                            title="Importer Liste Des Articles" @click="fetchItems">
                            <feather-icon icon="UploadCloudIcon" />
                        </b-button>
                    </b-col>
                </b-row>
                <ag-grid :agGrid="agGrid" ref="agGrid" pageTitle="Liste des articles" @refresh="loadData"
                    @cellDoubleClicked="cellDoubleClicked" :autoSizeColumns="true" :showHeader="false" />

            </b-card>
        </b-overlay>
    </div>
</template>

<script>

import AgGrid from "@/views/components/ag-grid/AgGrid.vue"
import BLinkCellRenderer from '@/views/product/BLinkCellRendererWithImage';
import IconCellRenderer from "@/views/product/IconCellRenderer.vue";

export default {
    components: {
        AgGrid,
    },
    data() {
        return {
            busy: false,
            counter: 0,
            showProgress: false,
            progress: 0, // Progress value for the progress bar
            selected: null,
            searchQuery: "",
            MEDcolumnDefs: [
                {
                    headerName: "Description",
                    field: "description",
                    cellRendererFramework: 'bLinkCellRenderer',
                    cellRendererParams: { onButtonClick: this.cellDoubleClicked, },
                    width: 350
                },
                {
                    headerName: "Code",
                    field: "code",
                },
                {
                    headerName: "DCI",
                    field: "dciDescription",
                    coldId: "dciDescriptionMED"
                },
                {
                    headerName: "Code PCT",
                    field: "pct",
                    coldId: "pctMED"
                },
                {
                    headerName: "Code AMM",
                    field: "ammCode",
                    coldId: "ammCodeMED"
                },
                {
                    headerName: "Barcode",
                    field: "barCode",
                    coldId: "barcodeMED"
                },
                {
                    headerName: "Cartonnage",
                    field: "packaging",
                    coldId: "packagingMED"
                },
                {
                    headerName: "Prix H.T",
                    field: "priceHT",
                    coldId: "priceHTMED"
                },
                {
                    headerName: "Prix T.T.C",
                    field: "priceTTC",
                    coldId: "priceTTCMED"
                },
                {
                    headerName: "Prix Public",
                    field: "publicPrice",
                    coldId: "publicPriceMED"
                },
                {
                    headerName: "TVA",
                    field: "vat",
                    coldId: "vatMED"
                },
                {
                    headerName: "Forme",
                    field: "form",
                    coldId: "formMED"
                },
                {
                    headerName: "Présentation",
                    field: "presentation",
                    coldId: "presentationMED"
                },
                {
                    headerName: "Conditionnement primaire",
                    field: "primaryPackaging",
                    coldId: "primaryPackagingMED"
                },
                {
                    headerName: "Spécification",
                    field: "specificationPrimaryPackaging",
                    coldId: "specificationPrimaryPackagingMED"
                },
                {
                    headerName: "Classement VEIC",
                    field: "veic",
                    coldId: "veicMED"
                },
                {
                    headerName: "Classe Thérapeutique",
                    field: "_class",
                    coldId: "_classMED"
                },
                {
                    headerName: "Sous Classe",
                    field: "subClass",
                    coldId: "subClassMED"
                },
                {
                    headerName: "Laboratoire",
                    field: "vendorDescription",
                    coldId: "vendorDescriptionMED"
                },
                {
                    headerName: "Générique/Princeps",
                    field: "category",
                    coldId: "categoryMED"
                },
                {
                    headerName: "Tableau",
                    field: "_table",
                    coldId: "_tableMED"
                },
                {
                    headerName: "CNAM",
                    field: "apcnam",
                    cellRendererFramework: 'iconCellRenderer',
                    width: 100,
                    coldId: "apcnamMED"
                },
                {
                    headerName: "Bloqué",
                    field: "bloqued",
                    cellRendererFramework: 'iconCellRenderer',
                    width: 100,
                    coldId: "bloquedMED"
                },
                {
                    headerName: "Créé le",
                    field: "createdAt",
                    coldId: "createdAtMED"
                },
                {
                    headerName: "Modifié At",
                    field: "updatedAt",
                    coldId: "updatedAtMED"
                },
            ],
            PARAcolumnDefs: [
                {
                    headerName: "Description",
                    field: "description",
                    cellRendererFramework: 'bLinkCellRenderer',
                    cellRendererParams: { onButtonClick: this.cellDoubleClicked, },
                    width: 350
                },
                {
                    headerName: "Code",
                    field: "code",
                },
                {
                    headerName: "Fournisseur",
                    field: "vendorDescription",
                    colId: "VendorPara"
                },
                {
                    headerName: "Famille",
                    field: "familyDescription",
                    colId: "familyDescriptionPARA"
                },
                {
                    headerName: "Sous-Famille",
                    field: "subFamilyDescription",
                    colId: "subFamilyDescriptionPARA"
                },
                {
                    headerName: "Barcode",
                    field: "barCode",
                    colId: "BarcodePara"
                },
                {
                    headerName: "Cartonnage",
                    field: "packaging",
                    colId: "PackagingPara"
                },
                {
                    headerName: "Prix H.T",
                    field: "priceHT",
                    colId: "PriceHTPara"
                },
                {
                    headerName: "Prix T.T.C",
                    field: "priceTTC",
                    colId: "PriceTTCPara"
                },
                {
                    headerName: "TVA",
                    field: "vat",
                    colId: "tvaPara"
                },
                {
                    headerName: "Bloqué",
                    field: "bloqued",
                    cellRendererFramework: 'iconCellRenderer',
                    width: 100,
                    colId: "BloquedPara"
                },
                {
                    headerName: "Créé le",
                    field: "createdAt",
                    colId: "createdAtPara"
                },
                {
                    headerName: "Modifié At",
                    field: "updatedAt",
                    colId: "updatedAtPara"
                },
            ],
            showLoading: false,
            agGrid: {
                columnDefs: [
                    {
                        headerName: "Description",
                        field: "description",
                        cellRendererFramework: 'bLinkCellRenderer',
                        cellRendererParams: { onButtonClick: this.cellDoubleClicked, },
                        width: 350
                    },
                    {
                        headerName: "Code",
                        field: "code",
                    },
                    {
                        headerName: "DCI",
                        field: "dciDescription",
                        coldId: "dciDescriptionMED"
                    },
                    {
                        headerName: "Code PCT",
                        field: "pct",
                        coldId: "pctMED"
                    },
                    {
                        headerName: "Code AMM",
                        field: "ammCode",
                        coldId: "ammCodeMED"
                    },
                    {
                        headerName: "Barcode",
                        field: "barCode",
                        coldId: "barcodeMED"
                    },
                    {
                        headerName: "Cartonnage",
                        field: "packaging",
                        coldId: "packagingMED"
                    },
                    {
                        headerName: "Prix H.T",
                        field: "priceHT",
                        coldId: "priceHTMED"
                    },
                    {
                        headerName: "Prix T.T.C",
                        field: "priceTTC",
                        coldId: "priceTTCMED"
                    },
                    {
                        headerName: "Prix Public",
                        field: "publicPrice",
                        coldId: "publicPriceMED"
                    },
                    {
                        headerName: "TVA",
                        field: "vat",
                        coldId: "vatMED"
                    },
                    {
                        headerName: "Forme",
                        field: "form",
                        coldId: "formMED"
                    },
                    {
                        headerName: "Présentation",
                        field: "presentation",
                        coldId: "presentationMED"
                    },
                    {
                        headerName: "Conditionnement primaire",
                        field: "primaryPackaging",
                        coldId: "primaryPackagingMED"
                    },
                    {
                        headerName: "Spécification",
                        field: "specificationPrimaryPackaging",
                        coldId: "specificationPrimaryPackagingMED"
                    },
                    {
                        headerName: "Classement VEIC",
                        field: "veic",
                        coldId: "veicMED"
                    },
                    {
                        headerName: "Classe Thérapeutique",
                        field: "_class",
                        coldId: "_classMED"
                    },
                    {
                        headerName: "Sous Classe",
                        field: "subClass",
                        coldId: "subClassMED"
                    },
                    {
                        headerName: "Laboratoire",
                        field: "vendorDescription",
                        coldId: "vendorDescriptionMED"
                    },
                    {
                        headerName: "Générique/Princeps",
                        field: "category",
                        coldId: "categoryMED"
                    },
                    {
                        headerName: "Tableau",
                        field: "_table",
                        coldId: "_tableMED"
                    },
                    {
                        headerName: "CNAM",
                        field: "apcnam",
                        cellRendererFramework: 'iconCellRenderer',
                        width: 100,
                        coldId: "apcnamMED"
                    },
                    {
                        headerName: "Bloqué",
                        field: "bloqued",
                        cellRendererFramework: 'iconCellRenderer',
                        width: 100,
                        coldId: "bloquedMED"
                    },
                    {
                        headerName: "Créé le",
                        field: "createdAt",
                        coldId: "createdAtMED"
                    },
                    {
                        headerName: "Modifié At",
                        field: "updatedAt",
                        coldId: "updatedAtMED"
                    },
                ],
                rows: []
            },
        }
    },
    computed: {
        tableData() {
            return this.$store.getters["app-items/geItemsMED"];
        },
    },
    watch: {
        tableData(products) {
            if (products.length > 0) {
                this.showLoading = false;
                this.agGrid.rows = [...products]
                    .sort((a, b) => {
                        const dateA = new Date(`${a.updatedAt.split(' - ')[0].split('-').reverse().join('-')}T${a.updatedAt.split(' - ')[1]}`);
                        const dateB = new Date(`${b.updatedAt.split(' - ')[0].split('-').reverse().join('-')}T${b.updatedAt.split(' - ')[1]}`);
                        return dateB - dateA;
                    });
            }
        },
    },
    created() {
        this.$options.components.bLinkCellRenderer = BLinkCellRenderer;
        this.$options.components.iconCellRenderer = IconCellRenderer;
        if (this.$store.getters["app-items/geItemsMED"].length > 0) {
            setTimeout(() => {
                this.agGrid.rows = this.$store.getters["app-items/geItemsMED"]
                    .sort((a, b) => {
                        const dateA = new Date(`${a.updatedAt.split(' - ')[0].split('-').reverse().join('-')}T${a.updatedAt.split(' - ')[1]}`);
                        const dateB = new Date(`${b.updatedAt.split(' - ')[0].split('-').reverse().join('-')}T${b.updatedAt.split(' - ')[1]}`);
                        return dateB - dateA;
                    });
            }, 1000);
        }
    },
    methods: {
        async fetchItems() {
            localStorage.setItem("lastItemUpdate", "01-01-2000 - 00:00:00");
            this.counter = 0;
            this.processing = this.busy = true;
            await this.$store.dispatch('app-items/FETCH_ITEMS_FROM_BACKEND_WITH_PERCENTAGE', this.updateProgress);
            setTimeout(() => {
                this.busy = this.processing = false;
                this.loadData();
            }, 2000);
        },
        updateProgress(value) {
            this.counter = Math.floor(value);
        },
        medClicked() {
            this.searchQuery = "";
            this.showLoading = true;
            this.agGrid.columnDefs = this.MEDcolumnDefs;
            this.agGrid.rows = this.$store.getters["app-items/geItemsMED"]
                .sort((a, b) => {
                    const dateA = new Date(`${a.updatedAt.split(' - ')[0].split('-').reverse().join('-')}T${a.updatedAt.split(' - ')[1]}`);
                    const dateB = new Date(`${b.updatedAt.split(' - ')[0].split('-').reverse().join('-')}T${b.updatedAt.split(' - ')[1]}`);
                    return dateB - dateA;
                });
            this.showLoading = false;
        },
        paraClicked() {
            this.searchQuery = "";
            this.showLoading = true;
            this.agGrid.columnDefs = this.PARAcolumnDefs;
            this.agGrid.rows = this.$store.getters["app-items/geItemsPARA"]
                .sort((a, b) => {
                    const dateA = new Date(`${a.updatedAt.split(' - ')[0].split('-').reverse().join('-')}T${a.updatedAt.split(' - ')[1]}`);
                    const dateB = new Date(`${b.updatedAt.split(' - ')[0].split('-').reverse().join('-')}T${b.updatedAt.split(' - ')[1]}`);
                    return dateB - dateA;
                });
            // setTimeout(() => {
            //     if (!/Mobi/i.test(navigator.userAgent))
            //         this.$refs.agGrid.gridApi.sizeColumnsToFit();
            // }, 200);
            this.showLoading = false;
        },
        async showDetails(data) {
            this.$router.push({ name: "supervisor-item-sheet", params: { code: data.code } });
        },
        loadData() {
            this.showLoading = true;
            this.$store.dispatch('app-items/FETCH_ITEMS_FROM_BACKEND');
        },
        updateSearchQuery(val) {
            const gridApi = this.$refs.agGrid.gridApi;
            gridApi.setQuickFilter(val);
        },
        cellDoubleClicked(param) {
            this.showDetails(param.data == undefined ? param : param.data);
        }
    }
}
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
    .card {
        background-color: initial;
        box-shadow: initial;
    }

    .card-body {
        padding: 0px;
    }
}

// .lazy-content {
//     /* Add styles to visually indicate lazy-loaded content */
//     font-style: italic;
//     /* Example: Italicize the text */
//     color: #999;
//     opacity: 0.5;
//     /* Initially set opacity to 0 */
//     transition: opacity 0.7s;
// }</style>


<style>
/* .loading-placeholder {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #f3f3f3;
    z-index: 999;
    overflow: hidden;
} */

/* .loading-background {
    height: 10px;
    background: linear-gradient(to right, #f3f3f3 8%, #e5e5e5 18%, #f3f3f3 33%);
    background-size: 800px 104px;
    position: relative;
    animation: shimmer 1s linear infinite;
} */

.shimmer-line {
    height: 200px;
    background: linear-gradient(to right, #f3f3f3 8%, #e5e5e5 18%, #f3f3f3 33%);
    background-size: 800px 104px;
    /* position: relative; */
    /* margin: 10px 0; */
}

@keyframes shimmer {
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
}
</style>
